<template>
    <div class="upload-image">
        <div class="input-wrap" @click="$refs.uploadImageInput.click()">
            <span class="btn btn-primary inline-block">Загрузить изображения</span>
            <input
                ref="uploadImageInput"
                type="file"
                :accept="acceptExtensions"
                multiple
                class="input"
                @change="uploadFile"
            />
        </div>
        <div class="preview grid grid-cols-12 gap-6">
            <div v-for="(image, key) in previewImages" :key="key" class="item col-span-3">
                <img :src="image[srcKey]" :alt="image[srcKey]" />
                <span @click="remove(image, key)"><XCircleIcon /></span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UploadMultiImage',
    props: {
        srcKey: {
            type: String,
            default: 'image',
        },
        images: Array,
        modelValue: Array,
        delete: Array,
    },
    data() {
        return {
            acceptExtensions: 'image/jpeg, image/png, image/gif, image/webp',
            previewImages: [],
        };
    },
    watch: {
        images: function (val) {
            this.previewImages = val;
        },
    },
    methods: {
        uploadFile(e) {
            const copyValue = this.modelValue ? this.modelValue : [];
            const files = [];

            for (const file of e.target.files) {
                const blob = window.URL.createObjectURL(new Blob([file]));
                this.previewImages.push({ [this.srcKey]: blob });
                files.push({ file: file, local_url: blob });
            }

            this.$emit(`update:modelValue`, [...copyValue, ...files]);
        },
        remove(image, key) {
            this.previewImages.splice(key, 1);

            if (image.id) {
                this.delete.push(image.id);
                this.$emit(`update:delete`, this.delete);
            } else {
                window.URL.revokeObjectURL(image[this.srcKey]);
                const fileIndex = this.modelValue.indexOf((file) => file.local_url === image[this.srcKey]);
                this.modelValue.splice(fileIndex, 1);
                this.$emit(`update:modelValue`, this.modelValue);
            }
        },
    },
};
</script>

<style scoped lang="scss">
.upload-image {
    box-sizing: border-box;
    min-height: 150px;
    background: white;
    padding: 20px 20px;
    border: 1px dashed #cbd5e0;
}

.input-wrap {
    padding-bottom: 15px;
}

.input {
    visibility: hidden;
}

.preview {
    border-top: 1px solid #cbd5e0;
    height: auto;
    width: 100%;
    max-height: 480px;
    overflow: hidden;
    overflow-y: auto;

    .item {
        margin: 10px;
        position: relative;

        span {
            position: absolute;
            right: -5px;
            top: -5px;
            cursor: pointer;
            background-color: #fff;
            border-radius: 50%;
        }
    }
}
</style>
